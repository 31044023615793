<template>
  <!-- Big Card -->
  <div class="col-xl-6 mb-3 mb-lg-0">
    <div class="card border-radius-9 border-0 bg-primary h-100 hover-shadow">
      <div class="card-body row align-items-center">
        <div
          class="text-right position-absolute mb-md-2 mb-lg-0"
          style="z-index: 1; top: 17px; right: 17px"
        >
          <span class="text-white mr-2">
            {{ updateDateTime | dateFormat }}
          </span>
          <span
            class="btn btn-outline-light border-0 btn-sm"
            v-on:click="onRefresh"
            ><i class="fas fa-sync"></i
          ></span>
        </div>

        <template v-if="data.loading && showLoader">
          <div class="col" style="min-height: 158px">
            <WeLoading />
          </div>
        </template>
        <div
          v-else
          class="col text-center"
          v-bind:class="{ 'border-right': i !== 3 }"
          v-for="(item, i) in data.reports"
          v-bind:key="i"
        >
          <SingleCard v-bind:data="item" />
        </div>
      </div>
    </div>
  </div>
  <!-- ./Big Card -->
</template>
<script>
const SingleCard = () => import("./components/Item/Index");

export default {
  name: "BigCard",
  data() {
    return {
      updateDateTime: null,
      timer: null,
      showLoader: false,
    };
  },
  components: {
    SingleCard,
  },
  props: {
    data: null,
  },

  methods: {
    onRefresh(showLoader = false) {
      const data = {};
      data.name = "bulkReport";

      this.$emit("on-refresh", data);
      this.setUpdateDateTime();
      this.showLoader = showLoader;
    },
    setUpdateDateTime() {
      this.updateDateTime = new Date(Date.now());
    },
    updateTimer() {
      this.timer = new Date(Date.now());
    },
  },
  mounted() {
    this.onRefresh(true);
  },
  watch: {
    timer: function (newVal) {
      let difference = helper.getTimerDiff(this.timer, this.updateDateTime);

      if (difference >= 3) {
        this.onRefresh();
      }
    },
  },
  created() {
    setInterval(this.updateTimer, 1000);
  },
};
</script>
<style lang="scss">
.select--transparent {
  background-color: transparent;
  border: 0;
  font-size: 0.8em;
  padding: 0;
  color: var(--muted);
  option:not(:checked) {
    color: #000;
  }
}
.border-right {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
