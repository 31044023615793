<template>
  <div class="row mb-2 align-items-stretch">
    <!-- <BigCard v-bind:data="bigCardData" v-on:on-refresh="onRefresh" /> -->
    <!-- Card -->
    <WeStatsCard
      v-for="(card, index) in cardList"
      v-bind:key="index"
      v-bind:card-index="index"
      v-bind:cards="cardList"
      v-on:on-refresh="onRefresh"
    />
    <!-- ./Card -->
  </div>
</template>
<script>
import BigCard from "./BigCard/Index";
import { mapActions } from "vuex";
export default {
  name: "Cards",
  data() {
    return {
      bigCardData: {
        name: "bulkReport",
        reports: [],
        loading: true,
      },
      cardList: [
        {
          name: "totalOrders",
          title: "Başarılı Siparişler",
          bgImage: "/img/icons/dashboard/order.svg",
          imageMargin: "mb-n3 mr-n1",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-pink",
          loading: true,
        },
        {
          name: "totalSalePrice",
          title: "Toplam Sipariş Tutarı",
          bgImage: "/img/icons/dashboard/money.png",
          imageMargin: "mb-n3 mr-n1",
          routerLink: "daily-order-reports",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-indigo",
          loading: true,
        },
        {
          name: "newMembers",
          title: "Yeni Üyeler",
          bgImage: "/img/icons/dashboard/member-group.png",
          imageMargin: "mb-n1 mr-n2",
          routerLink: "member-reports",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-teal",
          loading: true,
        },
        {
          name: "singleOrderAverage",
          title: "Ortalama Sepet Tutarı",
          bgImage: "/img/icons/dashboard/discount.png",
          imageMargin: "mb-n3 mr-n3",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-blue",
          loading: true,
        },
        {
          name: "cancelledReturnsQuantity",
          title: "İptal & İade Miktarı",
          bgImage: "/img/icons/dashboard/return-2.png",
          imageMargin: "mb-n2",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-red",
          loading: true,
        },
        {
          name: "cancelledReturnsPrice",
          title: "İptal & İade Tutarı",
          bgImage: "/img/icons/dashboard/return-2.png",
          imageMargin: "mb-n2",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-orange",
          loading: true,
        },
        {
          name: "marketplaceOrders",
          title: "Pazaryeri Siparişleri",
          bgImage: "/img/icons/dashboard/marketplace.png",
          imageMargin: "mb-n2 mr-n2",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-tr",
          loading: true,
        },
        {
          name: "marketplaceReturns",
          title: "Pazaryeri İptal & İade",
          bgImage: "/img/icons/dashboard/marketplace-return.png",
          imageMargin: "mb-n2 mr-n2",
          textValue: 0,
          last_week_percent: 0,
          cardClass: "bg-gradient-yellow",
          loading: true,
        },
      ],
    };
  },
  components: {
    BigCard,
  },
  methods: {
    ...mapActions("widget", ["refreshReport"]),
    onRefresh(data) {
      let statCard = null;

      if (data.name == "bulkReport") {
        this.bigCardData.loading = true;
      } else {
        statCard = this.cardList[data.index];
        statCard.loading = true;
      }

      this.refreshReport({
        name: data.name,
        onSuccess: (result) => {
          let totalValue = result.data.count;

          if (
            data.name == "totalSalePrice" ||
            data.name == "cancelledReturnsPrice" ||
            data.name == "singleOrderAverage"
          ) {
            totalValue = localization.getMoneyFormat(null, result.data.count);
          } else {
            totalValue = new Intl.NumberFormat("tr-TR").format(totalValue);
          }

          statCard.price =
            result.data && result.data.price ? result.data.price : 0;
          statCard.textValue = totalValue;
          statCard.percent =
            result.data && result.data.percent ? result.data.percent : 0;
          statCard.loading = false;
        },
      });
    },
  },
};
</script>
<style lang="scss">
.border-white-2 {
  border-color: rgba(255, 255, 255, 0.2);
}
.icon-transparent {
  color: rgba(0, 0, 0, 0.6);
}
.bg-gradient-orange {
  background: linear-gradient(to left, #ee7b5e, #f1957e) !important;
}
.bg-gradient-teal {
  background: linear-gradient(to left, #1aa57c, #20c997) !important;
}
.bg-gradient-indigo {
  background: linear-gradient(to left, #8845f5, #aa7af8) !important;
}
.bg-gradient-pink {
  background: linear-gradient(to left, #d3488e, #da65a0) !important;
}
.bg-gradient-red {
  background: linear-gradient(to left, #d34848, #da6565) !important;
}
.bg-gradient-blue {
  background: linear-gradient(to left, #4861d3, #6594da) !important;
}
.bg-gradient-tr {
  background: linear-gradient(to left, #48c3d3, #65b5da) !important;
}
.bg-gradient-yellow {
  background: linear-gradient(to left, #c4ae34, #dde005) !important;
}
.progress {
  height: 6px;
}
.border-radius-9 {
  border-radius: 9px;
}
</style>
